<template>
  <b-overlay :show="loading">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <b-form  @submit.prevent="handleSubmit(register)">
        <ValidationProvider name="organization" vid="org_id" rules="required|min_value:1">
          <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="org_id"
            slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
              {{$t('orgProfile.org_name')}} <span class="text-danger">*</span>
              </template>
            <b-form-select
              plain
              v-model="report_heading.org_id"
              :options="orgList"
              id="org_id"
              :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider name="Heading (En)" vid="project_name" rules="required">
          <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="heading"
            slot-scope="{ valid, errors }"
          >
            <template v-slot:label>
              {{$t('elearning_config.heading_name_en')}} <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="heading"
              v-model="report_heading.project_name"
              :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider name="Heading (Bn)" vid="project_name_bn" rules="required">
          <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="heading_bn"
            slot-scope="{ valid, errors }"
          >
            <template v-slot:label>
              {{$t('elearning_config.heading_name_bn')}} <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="heading_bn"
              v-model="report_heading.project_name_bn"
              :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider name="Email" vid="email" rules="required|email">
          <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="email"
            slot-scope="{ valid, errors }"
          >
            <template v-slot:label>
              {{$t('globalTrans.email')}} <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="email"
              v-model="report_heading.email"
              :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider name="Contant No" vid="mobile_no" rules="required|min:11|max:11">
          <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="mobile_no"
            slot-scope="{ valid, errors }"
          >
            <template v-slot:label>
              {{$t('globalTrans.mobile')}} <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="number"
              id="mobile_no"
              v-model="report_heading.mobile_no"
              :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider name="Address (En)" vid="address" rules="required" required>
          <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="address"
            slot-scope="{ valid, errors }"
          >
          <template v-slot:label>
            {{$t('globalTrans.address')}} {{$t('globalTrans.en')}} <span class="text-danger">*</span>
          </template>
            <b-form-textarea
              id="address"
              v-model="report_heading.address"
              rows="1"
              max-rows="2"
              :state="errors[0] ? false : (valid ? true : null)"
            ></b-form-textarea>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider name="Address (Bn)" vid="address_bn" rules="required">
          <b-form-group
            class="row"
            label-cols-sm="4"
            :label="$t('warehouse_config.address_bn')"
            label-for="address_bn"
            slot-scope="{ valid, errors }"
          >
          <template v-slot:label>
          {{$t('globalTrans.address')}} {{$t('globalTrans.bn')}} <span class="text-danger">*</span>
          </template>
            <b-form-textarea
              id="address_bn"
              v-model="report_heading.address_bn"
              rows="1"
              max-rows="2"
              :state="errors[0] ? false : (valid ? true : null)"
            ></b-form-textarea>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider name="Left Logo" vid="left_logo" :rules="report_heading.id === 1 ? '' : 'required'">
          <b-form-group
            class="row"
            label-cols-sm="4"
            :label="$t('warehouse_config.left_logo')"
            label-for="left_logo"
            slot-scope="{ valid, errors }"
          >
            <template v-slot:label>
              {{$t('bazarMonitoring.left_logo')}} <span class="text-danger">*</span>
            </template>
            <b-form-file
              id="left_logo"
              v-on:change="onLeftLogoChange"
              accept="image/*"
              v-model="report_heading.left_logo"
              :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-file>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider name="Right Logo" vid="right_logo" :rules="report_heading.id === 1 ? '' : 'required'">
          <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="right_logo"
            slot-scope="{ valid, errors }"
          >
            <template v-slot:label>
              {{$t('bazarMonitoring.right_logo')}} <span class="text-danger">*</span>
            </template>
            <b-form-file
              id="right_logo"
              v-on:change="onRightLogoChange"
              accept="image/*"
              v-model="report_heading.right_logo"
              :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-file>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider name="background Logo" vid="background_logo" :rules="report_heading.id === 1 ? '' : 'required'">
          <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="background_logo"
            slot-scope="{ valid, errors }"
          >
            <template v-slot:label>
              {{$t('elearning_config.background_logo')}} <span class="text-danger">*</span>
            </template>
            <b-form-file
              id="background_logo"
              v-on:change="onBackgroundLogoChange"
              accept="image/*"
              v-model="report_heading.background_logo"
              :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-file>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col text-right">
            <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
          </div>
        </div>
      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '../../../../../config/api_config'
import { reportHeadingStore, reportHeadingUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getReportHeadingData()
      this.report_heading = tmp
    } else {
        // this.report_heading = Object.assign({}, this.report_heading, {
        //     org_id: this.$store.state.dataFilters.orgId
        // })
    }
  },
  mounted () {
  },
  data () {
    return {
      loading: false,
      test: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      report_heading: {
        id: '',
        org_id: 0,
        project_name: '',
        project_name_bn: '',
        left_logo: [],
        right_logo: [],
        address: '',
        address_bn: '',
        email: '',
        mobile_no: ''
      },
      upload_left_logo: [],
      upload_right_logo: [],
      upload_background_logo: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    }
  },
  methods: {
    onLeftLogoChange (e) {
      this.upload_left_logo = e.target.files[0]
    },
    onRightLogoChange (e) {
      this.upload_right_logo = e.target.files[0]
    },
    onBackgroundLogoChange (e) {
      this.upload_background_logo = e.target.files[0]
    },
    getReportHeadingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.report_heading).map(key => {
        if (key === 'left_logo') {
          formData.append(key, this.upload_left_logo)
        }
        if (key === 'background_logo') {
          formData.append(key, this.upload_background_logo)
        }
        if (key === 'right_logo') {
          formData.append(key, this.upload_right_logo)
        } else {
          formData.append(key, this.report_heading[key])
        }
      })

      if (this.report_heading.id) {
        formData.append('_method', 'POST')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${reportHeadingUpdate}/${this.id}`, formData, config)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, reportHeadingStore, formData, config)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
      this.loading = false
    }
  }
}
</script>
