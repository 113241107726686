<template>
  <b-container fluid>
     <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('bazarMonitoring.report_heading')}}</h4>
      </template>
      <template v-slot:searchBody>
      <b-row>
        <b-col xs="12" sm="12" md="6">
            <ValidationProvider name="Organization" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="org_id"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="search.org_id"
                  :options="orgList"
                  id="org_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col xs="12" sm="12" md="6">
          <b-button size="sm" variant="primary" @click="searchData">
            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
          </b-button>
        </b-col>
      </b-row>
      </template>
    </card>
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('bazarMonitoring.report_heading') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
          <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
        </b-button>
      </template>
      <template v-slot:body>
        <select-column-check :labelData="labelData" :search="search" :columns="columns" />
        <b-overlay :show="loadingState">
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(heading)="data">
                    {{ data.item.heading }}
                  </template>
                  <template v-slot:cell(left_logo)="data">
                    <b-img width='100px' :src="trainingElearningServiceBaseUrl+'storage/'+data.item.left_logo" fluid alt="Left Logo"></b-img>
                  </template>
                  <template v-slot:cell(right_logo)="data">
                    <b-img width='100px' :src="trainingElearningServiceBaseUrl+'storage/'+data.item.right_logo" fluid alt="Right Logo"></b-img>
                  </template>
                  <template v-slot:cell(background_logo)="data">
                    <b-img width='100px' :src="trainingElearningServiceBaseUrl+'storage/'+data.item.background_logo" fluid alt="Background Logo"></b-img>
                  </template>
                  <template v-slot:cell(address)="data">
                    {{ data.item.address }}
                  </template>
                  <template v-slot:cell(org_id)="data">
                    {{ data.item.org_name }}
                  </template>
                  <template v-slot:cell(status)="data">
                      <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                      <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-b-modal.modal-4 variant="btn-edit" size="sm" @click="edit(data.item)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                    <b-button :variant="data.item.status === 2 ? ' btn-danger' : ' btn-success'" size="sm" class="action-btn status" :title="data.item.status === 2 ? $t('globalTrans.inactive') : $t('globalTrans.active')" @click="remove(data.item)">
                    <i :class="data.item.status === 2  ? 'ri-toggle-line' : 'ri-toggle-fill'"></i>
                    </b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                v-model="pagination.currentPage"
                :perPage="search.limit"
                :total-rows="pagination.totalRows"
                @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
    </body-card>
    <b-modal id="modal-4" size="lg" hide-footer :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import FormV from './FormV'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, reportToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      sortBy: '',
      sortDirection: '',
      sortDesc: '',
      search: {
        org_id: 0,
        limit: 20
      },
      rows: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
        { labels: 'orgProfile.org_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
        { labels: 'bazarMonitoring.heading', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '30%' } },
        { labels: 'globalTrans.address', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '30%' } },
        { labels: 'bazarMonitoring.left_logo', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '30%' } },
        { labels: 'bazarMonitoring.right_logo', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '30%' } },
        { labels: 'elearning_config.background_logo', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '30%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 9, thStyle: { width: '15%' } }
      ],
      actions: {
        edit: true,
        toogle: true
      }
    }
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('bazarMonitoring.report_heading') + ' ' + this.$t('globalTrans.entry') : this.$t('bazarMonitoring.report_heading') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'org_name_bn' },
          { key: 'project_name_bn' },
          { key: 'address_bn' },
          { key: 'left_logo' },
          { key: 'right_logo' },
          { key: 'background_logo' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'org_name' },
          { key: 'project_name' },
          { key: 'address' },
          { key: 'left_logo' },
          { key: 'right_logo' },
          { key: 'background_logo' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList
    },
    pageOptions () {
        return this.$store.state.commonObj.pageOptions
    }
  },
  created () {
    // this.search = Object.assign({}, this.search, {
    //     org_id: this.$store.state.dataFilters.orgId
    // })
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  methods: {
    dataChange () {
      this.loadData()
    },
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(trainingElearningServiceBaseUrl, reportToggleStatus, item)
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(trainingElearningServiceBaseUrl, reportHeadingList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.dataList(response.data.data))
          this.paginationData(response.data, this.search.limit)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    dataList (data) {
      const listData = data.map((item, index) => {
        const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(law => law.value === parseInt(item.org_id))
        const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org_name = orgObj.text_en
            orgData.org_name_bn = orgObj.text_bn
          } else {
            orgData.org_name = ''
            orgData.org_name_bn = ''
          }
        return Object.assign({}, item, { serial: index }, orgData)
      })
      return listData
    }
  }
}
</script>
